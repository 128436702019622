import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-vgap" }
const _hoisted_2 = { class: "flex-hgap" }

import { useSeoMeta } from '@unhead/vue'

const title = '404: Page Not Found'

export default /*@__PURE__*/_defineComponent({
  __name: '404Page',
  setup(__props) {

useSeoMeta({ title })

return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(title)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        to: "/",
        icon: "home",
        outline: "",
        label: "Go to Home Page"
      })
    ])
  ]))
}
}

})